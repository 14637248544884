import {
  FilteringFieldsetItemModel,
  FilteringFieldsetModel
} from "./chunk-C2G7CFMK.js";
import {
  Route_default
} from "./chunk-KI2SCADD.js";

// src/Pim/PimFilteringFieldset/index.ts
import axios from "axios";
var FilteringFieldsetApiService = class {
  constructor() {
    this.route = null;
    this.create = async (data) => {
      try {
        const result = await this.route.create(data);
        if (result) {
          return result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.update = async (data) => {
      try {
        const result = await this.route.update(data);
        if (result) {
          return result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.deleteFields = async (arr) => {
      try {
        const listSelected = await arr.map((o) => {
          return { id: o };
        });
        const result = await this.route.deleteFields(listSelected);
        if (result) {
          return result.result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getList = async (filter) => {
      try {
        const data = await this.route.getList(filter);
        const roleItems = new FilteringFieldsetModel(data);
        return {
          listItems: roleItems.getItems(),
          pagination: roleItems.getPagination()
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getDetail = async (id = 0) => {
      try {
        const data = await this.route.getDetail(id);
        let results = null;
        if (data) {
          results = new FilteringFieldsetItemModel(data);
        }
        if (results) {
          results = results.toJSON();
        }
        return results;
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.route = new Route_default();
  }
};

export {
  FilteringFieldsetApiService
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
