import {
  translations_default
} from "./chunk-YKLMKPTR.js";
import {
  DamStore
} from "./chunk-5ZUPCYAY.js";

// src/integration/lib/AesirXDamStorage.jsx
import React2 from "react";

// src/components/Storage/index.jsx
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// src/components/Storage/index.scss
var css = `.mini_left .damstorage {
  display: none;
}

.damstorage {
  position: relative;
}`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/components/Storage/index.jsx
import { Image } from "aesirx-uikit";
import { DAM_SUBSCIPTION_FIELD_KEY } from "aesirx-lib";

// src/components/Storage/storage.svg
var storage_default = 'data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">%0A<g clip-path="url(%23clip0_801_1929)">%0A<path d="M22 4.45991C21.88 2.18658 15.6867 1.57324 12 1.57324C8.31333 1.57324 2 2.19324 2 4.51991V19.5799C2 21.9066 8.28667 22.5332 12 22.5332C15.7133 22.5332 22 21.9132 22 19.5799V4.45991ZM20.6667 9.49991C20.4467 10.0732 17.2933 11.1332 12 11.1332C9.52412 11.1964 7.05261 10.8909 4.66667 10.2266V11.6132C7.06454 12.2102 9.52912 12.497 12 12.4666C14.6667 12.4666 18.62 12.1466 20.6667 11.1332V14.5799C20.4467 15.1532 17.2933 16.2132 12 16.2132C9.52278 16.2678 7.05125 15.9532 4.66667 15.2799V16.6666C7.06454 17.2636 9.52912 17.5504 12 17.5199C14.6667 17.5199 18.62 17.1999 20.6667 16.1866V19.5866C20.4333 20.1599 17.28 21.2199 12 21.2199C6.72 21.2199 3.53333 20.1332 3.33333 19.5799V4.54658C3.53333 3.99991 6.66667 2.90658 12 2.90658C17.18 2.90658 20.3067 3.92658 20.6667 4.48658C20.32 5.06658 17.1933 6.07991 12 6.07991C9.52419 6.14161 7.0529 5.83607 4.66667 5.17324V6.56658C7.0651 7.15982 9.52948 7.44434 12 7.41324C14.6667 7.41324 18.62 7.09324 20.6667 6.07991V9.49991Z" fill="white"/>%0A</g>%0A<defs>%0A<clipPath id="clip0_801_1929">%0A<rect width="24" height="24" fill="white"/>%0A</clipPath>%0A</defs>%0A</svg>%0A';

// src/components/Storage/index.jsx
var gigabyte = 1e9;
var mbToByte = 1e6;
function formatBytes(bytes, decimals = 2) {
  if (!+bytes)
    return 0;
  const k = 1e3;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return {
    usageValue: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) ?? 0,
    usageType: sizes[i] ?? "KB"
  };
}
var calculatorPercentage = (a, b) => {
  return a / b * 100;
};
var Storage = () => {
  const [subscription, setSubscription] = useState(null);
  const { t } = useTranslation();
  const getSubscription = async () => {
    try {
      const store = new DamStore();
      const subscriptionDetail = await store.getSubscription();
      const usage = subscriptionDetail[DAM_SUBSCIPTION_FIELD_KEY.PRODUCT_STORAGE_USAGE];
      const limit = subscriptionDetail[DAM_SUBSCIPTION_FIELD_KEY.PACKAGE_STORAGE_LIMIT];
      if (usage != void 0 && limit != void 0) {
        const percentage = calculatorPercentage(usage, limit);
        const isGb = limit >= gigabyte;
        const convertUsage = formatBytes(usage);
        const convertLimit = isGb ? limit / gigabyte : limit / mbToByte;
        setSubscription({
          [DAM_SUBSCIPTION_FIELD_KEY.PRODUCT_STORAGE_USAGE]: convertUsage.usageValue,
          [DAM_SUBSCIPTION_FIELD_KEY.PACKAGE_STORAGE_LIMIT]: convertLimit,
          limit_type: isGb ? "GB" : "MB",
          usage_type: convertUsage.usageType,
          percentage
        });
      }
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    if (!subscription) {
      getSubscription();
    }
    return () => {
    };
  }, [subscription]);
  return /* @__PURE__ */ React.createElement("div", { className: `damstorage w-100 mb-3 px-3 py-3` }, /* @__PURE__ */ React.createElement("p", { className: "mb-0" }, /* @__PURE__ */ React.createElement(Image, { src: storage_default }), /* @__PURE__ */ React.createElement("span", { className: "text-white ps-3" }, t("txt_storage"))), /* @__PURE__ */ React.createElement("div", { className: "progress my-3", style: { height: "5px" } }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "progress-bar bg-cyan",
      role: "progressbar",
      style: {
        width: `${subscription?.percentage ?? 0}%`
      },
      "aria-label": "Basic example",
      "aria-valuenow": subscription?.percentage ?? 0,
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  )), /* @__PURE__ */ React.createElement("p", { className: "mb-0 d-flex flex-wrap " }, /* @__PURE__ */ React.createElement("span", { className: "text-white fs-14" }, subscription?.[DAM_SUBSCIPTION_FIELD_KEY.PRODUCT_STORAGE_USAGE] ? subscription?.[DAM_SUBSCIPTION_FIELD_KEY.PRODUCT_STORAGE_USAGE] : 0, subscription?.usage_type ?? "MB", " ", t("txt_of"), " ", subscription?.[DAM_SUBSCIPTION_FIELD_KEY.PACKAGE_STORAGE_LIMIT] ? subscription?.[DAM_SUBSCIPTION_FIELD_KEY.PACKAGE_STORAGE_LIMIT] : 0, subscription?.limit_type ?? "MB", " ", t("txt_used")), /* @__PURE__ */ React.createElement(
    "a",
    {
      href: "https://dapp.shield.aesirx.io/licenses",
      className: "text-cyan text-decoration-underline fs-14 d-inline-block ps-1",
      target: "_blank",
      rel: "noreferrer"
    },
    t("txt_upgrade")
  )));
};
var Storage_default = Storage;

// src/integration/lib/AesirXDamStorage.jsx
import { AesirXI18nextProvider, ErrorBoundary } from "aesirx-uikit";
var AesirXDamStorage = (props) => {
  return /* @__PURE__ */ React2.createElement(ErrorBoundary, null, /* @__PURE__ */ React2.createElement(AesirXI18nextProvider, { appLanguages: translations_default }, /* @__PURE__ */ React2.createElement("div", { className: "dam-integrate-layout" }, /* @__PURE__ */ React2.createElement(Storage_default, { ...props }))));
};
var AesirXDamStorage_default = AesirXDamStorage;

export {
  AesirXDamStorage_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
